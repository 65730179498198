import './ButtonComponent.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { appDesktop } from '../libs/appLib'

export default function ButtonComponent(props) {
  const { label, onClick, size, type, block, blockMobile, disabled, className } = props
  const isDesktop = appDesktop()

  const isBlock = block || (blockMobile && !isDesktop)

  const onClickHandler = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <button className={['c-button', `c-button--${size}`, `c-button--${type}`, isBlock ? 'is-block' : '', className].join(' ')} onClick={onClick ? onClickHandler : null} disabled={disabled}>
      {label}
    </button>
  )
}
export const ButtonComponentPropTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['default', 'small', 'large']),
  type: PropTypes.oneOf(['primary', 'secondary', 'white', 'alert', 'success']),
  block: PropTypes.bool,
  blockMobile: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
}
ButtonComponent.propTypes = ButtonComponentPropTypes
ButtonComponent.defaultProps = {
  className: '',
  size: 'default',
  type: 'primary'
}
