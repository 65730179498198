import './FormComponent.scss'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ButtonComponent from './ButtonComponent'
import { InputPropTypes } from './InputComponent'

export default function FormComponent(props) {
  const { onSubmit, inputs, errorMessage, onChange, onCancel, cancelLabel, submitLabel, submitType, submitDisabled, cancelDisabled } = props
  const [values, setValues] = useState(getDefaultValues(inputs))

  useEffect(() => {
    onChange(values)
  }, [values])

  useEffect(() => {
    const defaultValues = getDefaultValues(inputs)
    if (JSON.stringify(defaultValues) !== JSON.stringify(values)) {
      setValues(defaultValues)
    }
  }, [inputs])

  const onFieldChange = (name, value) => {
    setValues({ ...values, [name]: value })
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    onSubmit(values)
  }

  return (
    <form className="c-form" onSubmit={onFormSubmit}>
      {!!errorMessage && <div className="c-form__error">{errorMessage}</div>}

      {inputs.map(input => (
        <div className="c-form__input" key={input.name}>
          <label className="c-form__input-label">{input.label} {input.required && <span>*</span>}</label>
          <div className="c-form__input-input">
            <input.component {...{...input, component: undefined}} value={values[input.name]} onChange={(value) => onFieldChange(input.name, value)} />
          </div>
        </div>
      ))}

      <div className='c-form__actions'>
        {onCancel && <ButtonComponent className="c-form__action" label={cancelLabel} type='secondary' onClick={onCancel} disabled={cancelDisabled} blockMobile />}
        <ButtonComponent className="c-form__action" label={submitLabel} type={submitType} disabled={submitDisabled} blockMobile />
      </div>
    </form>
  )
}
export const FormPropTypes = {
  onSubmit: PropTypes.func.isRequired,
  inputs: PropTypes.arrayOf(PropTypes.shape({
    ...InputPropTypes, onChange: PropTypes.any, value: PropTypes.any,
    component: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired
  })),
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  onCancel: PropTypes.func,
  submitLabel: PropTypes.string,
  submitType: PropTypes.string,
  cancelLabel: PropTypes.string,
  submitDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
}
FormComponent.propTypes = FormPropTypes
FormComponent.defaultProps = {
  inputs: [],
  submitLabel: 'Conferma',
  cancelLabel: 'Annulla',
  onChange: () => {},
  submitType: 'primary',
}

////////////////////////////////////////////////////////////////////////////////

function getDefaultValues(inputs) {
  const values = {}
  inputs.forEach(input => {
    values[input.name] = input.value || ''
  })
  return values
}