import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from 'contexts/AppContext'
import ScreenComponent, { ScreenComponentItem } from 'components/ScreenComponent'
import CardComponent from 'components/CardComponent'
import AuthenticationLogoComponent from 'components/AuthenticationLogoComponent'
import FormInteractive from 'interactives/FormInteractive'
import TitleComponent from 'components/TitleComponent'
import { InputEmailComponent, InputPasswordComponent } from 'components/InputComponent'
import { getRequest } from 'libs/requestsLib'
import SpacerComponent from 'components/SpacerComponent'

export default function LoginScreen(props) {
  const { views } = useContext(AppContext)
  const [email, setEmail] = useState(null)

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get('t')
    if (!token) return

    getRequest('/apis/authentication/user-email-from-token', { token }).then((response) => {
      if (!response.result) return
      setEmail(response.user_email)
    })
  }, [])

  const formInputs = []
  if (!email) { formInputs.push({ name: 'email', label: 'Indirizzo email', component: InputEmailComponent, required: true }) }
  formInputs.push({ name: 'password', label: 'Password', component: InputPasswordComponent, required: true })
  formInputs.push({ name: 'password_confirmation', label: 'Ripeti la password', component: InputPasswordComponent, required: true })

  return (
    <ScreenComponent fullscreen center>
      <ScreenComponentItem size='full'>
        <AuthenticationLogoComponent src={views.logo_url} />
      </ScreenComponentItem>
      <ScreenComponentItem size='third'>
        <CardComponent>
          <TitleComponent
            title='Completamento profilo'
            subtitle="Per completare il tuo profilo devi compilare il form e scegliere la tua nuova password di accesso."
            align='center'
          />
          <SpacerComponent size='large' />
          <FormInteractive
            path='/apis/authentication/signup'
            forcedParams={email ? { email } : {}}
            inputs={formInputs}
            submitLabel='Conferma e accedi'
            onSuccess={() => window.location. assign('/')}
          />
        </CardComponent>
      </ScreenComponentItem>
    </ScreenComponent>
  )
}
