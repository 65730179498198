import React, { useContext } from 'react'
import { AppContext } from 'contexts/AppContext'

import FormInteractive from 'interactives/FormInteractive'
import LinkInteractive from 'interactives/LinkInteractive'

import ScreenComponent, { ScreenComponentItem } from 'components/ScreenComponent'
import CardComponent from 'components/CardComponent'
import AuthenticationLogoComponent from 'components/AuthenticationLogoComponent'
import { InputEmailComponent, InputPasswordComponent } from 'components/InputComponent'
import SpacerComponent from 'components/SpacerComponent'

export default function LinkRequestScreen(props) {
  const { views } = useContext(AppContext)

  const onSuccess = () => {
    return {
      title: 'Email inviata',
      message: "Ti abbiamo inviato un'email con il link per accedere al tuo profilo.",
      button: { label: 'Va bene', onClick: () => window.location. assign('/authentication/login') }
    }
  }

  return (
    <ScreenComponent fullscreen center>
      <ScreenComponentItem size='full'>
        <AuthenticationLogoComponent src={views.logo_url} />
      </ScreenComponentItem>
      <ScreenComponentItem size='third'>
        <CardComponent>
          <FormInteractive
            path='/apis/authentication/link-request'
            inputs={[
              { name: 'email', label: 'Inserisci il tuo indirizzo email', component: InputEmailComponent, required: true }
            ]}
            submitLabel='Continua'
            onSuccess={onSuccess}
          />

          <SpacerComponent />

          <div style={{ fontSize: 12, textAlign: 'center' }}>
            <LinkInteractive
              path='/authentication/login'
            >Torna al login</LinkInteractive>
          </div>
        </CardComponent>
      </ScreenComponentItem>
    </ScreenComponent>
  )
}
